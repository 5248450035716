<template>
		<v-form
			ref="form"
			v-model="valid"
			lazy-validation
			@submit.prevent="onFormSubmit"
		>
	<v-card>
		<v-card-title>
			添加预算信息
		</v-card-title>
		<v-divider></v-divider>
		<template v-if="workTypes.length">
			<div class="pa-4">
				<v-select
					v-model="post_work_type_id"
					:items="workTypes"
					item-text="name"
					:rules="requiredRules"
					dense
					outlined
					item-value="id"
					label="选择预算工种"
				></v-select>
				<v-text-field
						v-model="post_work_type_budget"
						outlined
						dense
						hide-details
						:rules="budgetRules"
						label="预算时间（小时）"
						required
				></v-text-field>
				<v-checkbox
					v-model="post_work_type_show_in_report"
					label="在报表中显示"
				></v-checkbox>
			</div>
			<v-divider></v-divider>
			<v-card-actions class="justify-end px-4 py-3">
				<v-btn
					:disabled="!valid"
					color="success"
					elevation="0"
					@click="validate"
					type="submit"
				>
					添加项目预算
				</v-btn>
			</v-card-actions>
		</template>
		<template v-else>
			<div class="pa-6 body-2 text-center">
				您已经添加了所有的工种预算信息。
			</div>
				<v-card-actions class="justify-center px-4 py-3">
				<v-btn
					color="error"
					text
					@click="$emit('on-close');"
				>
					关闭窗口
				</v-btn>
			</v-card-actions>
		</template>

		</v-card>
		<v-snackbar
			absolute
			top
			:color="snackbar.color"
			v-model="snackbar.model"
			>
			{{snackbar.message }}

			<template v-slot:action="{ attrs }">
				<v-btn
				text
				v-bind="attrs"
				@click="snackbar.model = false"
				>
				关闭
				</v-btn>
			</template>
		</v-snackbar>
	</v-form>
</template>

<script>
import ProjectBudgetService from '../../services/ProjectBudget';
import WorkTypeService from '../../services/WorkType';

export default {
	name: 'projectFrom',
	data: () => ({
		valid: true,
		requiredRules: [
			v => !!v || '必填内容',
		],
		budgetRules: [
			v => !!v || '必填内容',
			v => (v && !isNaN(v)) || `请输入数字。例如：30`,
		],
		project_id: null,
		snackbar: {
			message: '',
			color: 'success',
			model: false
		},
		workTypes: [],
		unavailableWorkTypes: [],
		post_work_type_id: null,
		post_work_type_budget: null,
		post_work_type_show_in_report: true
	}),
	props: {
		projectId: {
			type: Number,
			required: true,
		},
		budgetId: {
			type: Number,
			required: false,
		},
		existingWorkTypes: {
			type: Array,
			required: false,
			default: []
		}
	},
	watch: {
		existingWorkTypes(val){
			this.workTypes = [];
			this.unavailableWorkTypes = val;
			this.getWorkTypes();
		}
	},
	computed: {

	},
	created(){
		this.project_id = this.projectId;
		this.unavailableWorkTypes = this.existingWorkTypes;
		this.getWorkTypes();
	},
	methods: {
		validate () {
			this.$refs.form.validate();
		},
		reset () {
			this.$refs.form.reset()
		},
		async getWorkTypes(){
			try {
				let response = await WorkTypeService.getWorkTypeList(0)
				response.data.data.forEach(workType=>{
					if(!this.unavailableWorkTypes.includes(workType.id)){
						this.workTypes.push(workType);
					}
				})
				
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		onFormSubmit(){
			this.overlay = true;
			//create paylod body here
			const body = {
				"work_type_id": parseInt(this.post_work_type_id),
				"hours": parseInt(this.post_work_type_budget),
				"shows_in_report": this.post_work_type_show_in_report ? this.post_work_type_show_in_report : false
			}
			this.createProjectBudget(body);
		},
		async createProjectBudget(body){
			try {
				let response = await ProjectBudgetService.createBudget(this.project_id, body);

				if(response.data.statusCode === 200){
					this.snackbar.message = `项目预算${response.data.message}`;
					this.snackbar.color = 'success';
					this.snackbar.model = true;
					this.reset();
					this.$emit('on-submit-success');
				}
			} catch(error){
					this.snackbar.message = `错误信息：${error.data.message}`;
					this.snackbar.color = 'error';
					this.reset();this.snackbar.model = true;
			}
		}
	}
}
</script>
